export function getFlash(type, message, options = {})
{
    return '<div class="alert alert-' + type + ' alert-dismissible fade show rounded-0 mb-0" role="' + type + '" data-id="' + (options.id ?? '') + '">\n' +
        '                    ' + message + '\n' +
        '                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Zavřít"></button>\n' +
        '                </div>';

}

export function addFlash(type, message, options = {})
{
    const $ = import('jquery').then(({ default: $ }) => {
        let html = getFlash(type, message, options);
        $('.alert.alert-danger').remove();
        $('.navbar.sticky-top').after(html);
        $("html, body").animate({scrollTop: 0}, "slow");

        if (options.autoHide) {
            setTimeout(function () {
                $('.alert').slideUp(500);
            }, 3000);
        }
    });
}
