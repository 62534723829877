/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import * as Sentry from "@sentry/browser";
import { captureConsoleIntegration } from "@sentry/integrations";
import {addFlash} from './base';

export default addFlash;

const sentryDsn = document.querySelector('body').dataset.dsn || false;

Sentry.init({
    environment: 'prod',
    dsn: sentryDsn ? atob(atob(sentryDsn)) : false,

    tracesSampler: samplingContext => {
        if (samplingContext.parentSampled !== undefined) {
            return samplingContext.parentSampled
        }

        return 0.01;
    },

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/(.*)\.parkum\.cz\//, /^https:\/\/(.*)\.iview\.cz\//],


    integrations: [
        new Sentry.BrowserTracing(),
        // captureConsoleIntegration(),
    ],
});

// start the Stimulus application
import './bootstrap';

// default confing for datatables
export const datatable_default_config = {
    responsive: true,
    scrollX: true,
    pagingType: 'simple',
    stateSave: true,
    "language": {
        "lengthMenu": "Zobrazit _MENU_",
        "search": "Hledat:",
        "zeroRecords": "Nic nenalezeno",
        "info": "Zobrazit stránku _PAGE_ z _PAGES_",
        "infoEmpty": "Nejsou k dispozici žádné záznamy",
        "infoFiltered": "(vybráno ze všech _MAX_ záznamů)",
        "paginate": {
            "first": "První",
            "last": "Poslední",
            "next": "Další",
            "previous": "Předchozí"
        },
    }
};

document.addEventListener('DOMContentLoaded', async () => {
    // Sometime later
    try {

        const {replayIntegration} = await import("@sentry/browser");
        Sentry.addIntegration(replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        }));
    } catch (e) {
        console.error(e);
    }
});

// checking online/offline status
window.addEventListener('offline', () => {
    addFlash('danger', 'Jste offline! Není možné zaplatit', {id: 'offline'});
});
window.addEventListener('online', async () => {
    import('jquery').then(({ default: $ }) => {
        $('div.alert[data-id="offline"]').slideUp(500);
    });
});


// Set the date we're counting down to
/*
var countDownDate = new Date("Jan 5, 2024 15:37:25").getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date().getTime();

  // Find the distance between now and the count down date
  var distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  // Display the result in the element with id="demo"
  document.getElementById("demo").innerHTML = days + "d " + hours + "h "
  + minutes + "m " + seconds + "s ";

  // If the count down is finished, write some text 
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("demo").innerHTML = "EXPIRED";
  }
}, 1000);
*/